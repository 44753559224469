import { createGlobalStyle } from 'styled-components';
import { breakpoints } from './Variables';

const GlobalStyles = createGlobalStyle`
  :root {
    // Colours
    --black: #000000;
    --white: white;
    --pink: #D98F77;
    --grey: #9DA8AC;
    --greyDark: #9B9B9B;
    --greyVeryDark: #5B5B5B;

    // 12 column grid
    --col1: 8.33%;
    --col1Internal: 9.996%;
    --col2: 16.66%;
    --col3: 25%;
    --col4: 33.33%;
    --col5: 41.66%;
    --col6: 50%;
    --col7: 58.33%;
    --col8: 66.66%;
    --col9: 75%;
    --col10: 83.33%;
    --col11: 91.66%;
    --col12: 100%;

    // Fixed dimensions
    --navHeight: 13vh;
    --maxPageWidth: 1250px;

    // Font sizes
    // Base vw 1440
    --fsBody: calc(20px * 0.9);
    --fsTiny: calc(14px * 0.9);
    --fsTitle1: calc(48px * 0.9);
    --fsTitle2: calc(32px * 0.9);
    /* --fsBody: clamp(12.5px, calc(1.39vw * 0.9), 20px); //20 / 1440 = 1.39
    --fsTiny: clamp(9px, calc(0.95vw * 0.9), 14px); //14 / 1440 ...
    --fsTitle1: clamp(30px, calc(3.33vw * 0.9), 48px); //48
    --fsTitle2: clamp(19px, calc(2.10vw * 0.9), 32px); //32 */

    @media (min-width: ${breakpoints.breakLg}) {
      --navHeight: 13vh;
    }
  }

  * {
    box-sizing: border-box;
    /* cursor: none; */
  }

  html {
    color: var(--white);
    font-size: var(--fsBody);
    height: 100%;
  }

  body.modal {
    overflow: hidden;
  }

  // Expanded view of work project
  body.modal:not(.contact) {
    nav {
      z-index: 20;

      .logo a {
        /* color: var(--greyVeryDark); */
        color: rgba(0, 0, 0, 0.25);
      }
    }

    // Very short window – hide logo
    @media (max-height: 400px) {
      nav .logo {
        display: none;
      }
    }  
  }

  a,
  button.link {
    color: var(--white);
    text-decoration: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid var(--white);
    transition: all 0.75s;
    padding-top: 0.25em;
    margin-top: -0.25em;

    &:hover {
      border-top-color: var(--white);
      border-bottom-color: transparent;
    }
  }

  h1, h2, h3, h4, h5 {
    font-weight: normal;
    margin: 0;
    /* letter-spacing: 1px; */
  }

  h1 {
    font-size: var(--fsTitle1);
  }

  h2 {
    font-size: var(--fsTitle2);
  }

  .tiny {
    font-size: var(--fsTiny);
    line-height: var(--fsTiny);
  }

  main {
    /* letter-spacing: 1px; */
    height: 100%;
    position: relative;
    /* padding-left: var(--col2); */
    padding-top: 0.5rem;
  }
`;

export default GlobalStyles;
