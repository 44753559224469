import React, { useState } from 'react';

// Create a context for the current locale
const ContactContext = React.createContext();

export function ContactProvider({ children }) {
  const [contactVisible, setContactVisible] = useState(false);

  return (
    <ContactContext.Provider value={[contactVisible, setContactVisible]}>
      {children}
    </ContactContext.Provider>
  );
}

export default ContactContext;
