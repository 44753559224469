import React from 'react'
import styled from 'styled-components'
import landscapeMorning from '../images/landscape-morning.jpg'
import landscapeNight from '../images/landscape-night.jpg'
import mountains from '../images/mountains.png'
import cloudsBig from '../images/clouds-big.png'
import cloudsSmall from '../images/clouds-small.png'
import { breakpoints } from '../styles/Variables'

const LandscapeStyles = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-image: url(${landscapeMorning});
  transition: all 2s;

  .foreground {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(${mountains});
    background-size: cover;
    background-position: center center;
  }

  .slideshow {
    position: relative;
    z-index: 5;
    overflow: hidden;
    height: 18vh;
  }

  .images {
    background-size: contain;
    background-repeat-y: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 300%;
    animation: slideshow 350s linear infinite;
  }

  .layerFast {
    background-image: url(${cloudsBig});
  }

  .layerSlow {
    background-image: url(${cloudsSmall});
    animation: slideshow 600s linear infinite;
    height: 80%; // Avoid repeating
  }


  &.nightMode {
    background-color: black;
    background-image: url(${landscapeNight});

    .foreground {
      filter: saturate(0.5);
    }

    .slideshow {
      filter: saturate(0.3);
    }
  }


  @keyframes slideshow {
    0%    { left: 0; }
    100%  { left: -200%; }
  }
`

export default function Landscape({className}) {
  return <LandscapeStyles className={className}>
    <div className="slideshow">
      <div className="images layerSlow"></div>
      <div className="images layerFast"></div>
    </div>
    <div className="foreground"></div>
  </LandscapeStyles>
}