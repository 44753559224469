import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { graphql } from 'gatsby';
import Nav from './Nav';
import 'normalize.css'; // imported as dependency, see package.json
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Landscape from './Landscape';
import { ContactProvider } from './ContactContext';
import ContactButton from './ContactButton';
import SEO from './SEO';
import { breakpoints } from '../styles/Variables';

const ContentStyles = styled.main`
  height: 100vh;
  overflow: hidden;

  #cursor {
    display: none;
  }

  // Enable custom cursor for non touch screens
  @media (hover: hover) {
    #cursor {
      display: inline-block;
      position: fixed;
      width: 30px;
      height: 30px;
      top: -50%;
      left: -50%;
      margin: -15px 0 0 -15px;
      border-radius: 50%;
      background-color: pink;
      backface-visibility: hidden;
      transition: transform 0.2s ease-out;
      mix-blend-mode: difference;
      z-index: 1000;
      pointer-events: none;
    }
    #cursor.isMoving {
      transform: scale(0.8);
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    position: fixed;
    top: 1.5rem;
    right: 1rem;
    z-index: 10;
    font-size: var(--fsTiny);

    button {
      background: transparent;
      border: 1px solid var(--white);
      border-radius: 1em;
      text-indent: 9999px; // Hide text
      width: 2em;
      height: calc(1em + 1px);
      position: relative;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 0 0.5em;
      font-size: var(--fsBody);
      transition: all 0.5s;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background: var(--white);
        transition: all 0.5s;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    &.on {
      button {
        &::before {
          left: calc(100% - 1em);
        }
      }
    }
  }

  button.contact.main {
    position: fixed;
    z-index: 10;
    bottom: 1.5rem;
    left: 1.5rem;
  }

  &:not(.work) {
    button.contact.main {
      display: none;
    }
  }

  &::before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    /* mix-blend-mode: saturation; */
    opacity: 0;
    transition: all 1s;
  }

  &[data-path="work"] {
    padding-left: var(--col1);
    padding-right: var(--col1);
    overflow: visible;
    height: 100%;

    &::before {
      opacity: 1;
    }

    &::after {
      content: '';
      position: fixed;
      display: block;
      height: 6rem;
      left: 0;
      bottom: 0;
      width: 100%;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
      z-index: 9;
    }

    @media (max-width: ${breakpoints.breakSm}) {
      .buttonWrapper {
        display: none;
      }
    }
  }
`;

export default function Layout(props) {
  const { children, location } = props;
  const pathClassName = location.pathname.replaceAll ? location.pathname.replaceAll('/', '') : '';
  const [nightMode, setNightMode] = useState(false);

  console.log({pathClassName});

  // Create custom cursor
  let cursor = null;
  let timer2 = null
  useEffect(() => {
    cursor = document.getElementById('cursor');
    
    const moveCursor = (e) => {
      cursor.classList.add('isMoving');
      cursor.style.top = `${e.pageY}px`;
      cursor.style.left = `${e.pageX}px`;
      clearTimeout(timer2);
      timer2 = setTimeout(function() {
          cursor.classList.remove('isMoving');
      }, 300);
    }

    // if (window) {
      window.addEventListener('mousemove', moveCursor);
      // window.addEventListener('mousemove', moveCursor);
    // }

  }, cursor)

  return (
    <>
      <GlobalStyles />
      <Typography />
      <SEO title="Full stack web development" />
      <Landscape className={nightMode ? 'nightMode' : ''} />
      <ContactProvider>
        <Nav className={pathClassName} data-path={pathClassName} />
        <ContentStyles className={pathClassName} data-path={pathClassName}>
          {children}
          <div id="cursor"></div>
          <div className={`buttonWrapper ${nightMode ? 'on' : 'off'}`}>
            Morning
            <button type="button" onClick={() => setNightMode(!nightMode)}>Change</button>
            Night
          </div>
          <ContactButton className="main" />
        </ContentStyles>
      </ContactProvider>
    </>
  );
}

// Add fragments here if needed
// export const query = graphql`
//   fragment SiteSettingsFields on SanitySiteSettings {
//     instagram
//     facebook
//     twitter
//     _rawVaultLegend
//     _rawNewsletterLegend
//     contactCategories {
//       ...LocaleStringFields
//     }
//   }
// `;
