import React, { useContext } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../styles/Variables';
import ContactContext from './ContactContext';

const ContactStyles = styled.div`
  display: inline-block;

  button {
    display: inline-block;
    background: transparent;
    border-left: none;
    border-right: none;
    padding-left: 0;
    padding-right: 0;
  }

  .modal {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 0;
    left: 0;
    top: 0;
    background: var(--pink);
    color: var(--white);
    font-size: 3rem;
    opacity: 0;
    transition: all 0.5s;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: var(--white);
      border: none;
      position: absolute;
      left: calc(50% - 0.75rem);
      top: 0.5rem;
      transition: all 0.5s;

      &:hover {
        /* color: var(--black); */
        transform: scale(1.25);
      }
    }

    .email  a {
      margin: 0;
      transition: all 0.5s;
      border-width: 2px;
      display: inline-block;

      &:hover {
        transform: scale(1.025);
      }

      @media (max-width: ${breakpoints.breakSm}) {
        font-size: 1rem;
      }
    }

    &.visible {
      height: 100%;
      opacity: 1;
    }
  }
`;

export default function ContactButton({className}) {
  const [contactVisible, setContactVisible] = useContext(ContactContext);

  const doExpand = () => {
    document.body.classList.add('modal', 'contact')
    setContactVisible(true)
  }

  const doClose = () => {
    document.body.classList.remove('modal', 'contact')
    setContactVisible(false)
  }

  return <ContactStyles>
    <button 
      type="button" 
      className={`contact link ${className}`}
      onClick={doExpand}
    >
      Contact
    </button>
    <div className={`modal ${contactVisible ? 'visible' : ''}`}>
      <button 
        type="button" 
        onClick={doClose}
        title="close"
      >
        &times;
      </button>
      <span className="email">
        <a href="mailto:letsdoit@balthazaurus.com">letsdoit@balthazaurus.com</a>
      </span>
    </div>
  </ContactStyles>
}