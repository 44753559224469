import { createGlobalStyle } from 'styled-components';
import caslon from '../fonts/Caslon540.woff';
import adam from '../fonts/adam.woff';

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'Caslon';
    src: url(${caslon});
    font-style: normal;
  }

  @font-face {
    font-family: 'Adam';
    src: url(${adam});
    font-style: normal;
  }
  
  html {
    font-family: Caslon, Baskerville, Georgia, serif;
  }
`;

export default Typography;
