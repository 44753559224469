import { Link } from 'gatsby';
import React from 'react'
import styled from 'styled-components'

const NavStyles = styled.nav`
  /* height: var(--navHeight); */
  position: relative;
  z-index: 10;
  mix-blend-mode: difference;

  .logo {
    position: fixed;
    display: inline-block;
    font-family: 'Adam';
    transform: rotate(90deg);
    transform-origin: left;
    transition: all 0.5s;
    left: 1.5rem;
    top: -1.5rem;
    font-size: 7.75vh;
    /* left: 2.5rem;
    top: -0.5em;
    font-size: 13vh; */

    a {
      /* TODO: night colour */
      border: none;
      text-decoration: none;
      color: white;

      &:hover {
        border: none;
      }
    }
  }

  &[data-path="work"] {
    mix-blend-mode: unset;

    .logo {
      transform: unset;
      width: 0.6em;
      overflow: hidden;
      top: 1rem;
      left: 1rem;
    }
  }
`;

const Nav = (props) => {
  const { className } = props;

  return <NavStyles className={className} data-path={props['data-path']}>
    <div className="logo">
      <Link to="/">Balthazaurus</Link>
    </div>
  </NavStyles>
}

export default Nav